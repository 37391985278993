import React from 'react';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import {
  Avatar,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid2 as Grid,
  LinearProgress,
  Stack,
  Typography,
} from '@mui/material';
import dashedCircle from '../images/dashedCircle.svg';
import deleteMe1 from '../images/deleteMe1.svg';
import deleteMe2 from '../images/deleteMe2.svg';

const ACCENT_COLOR = '#3787FF';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
    width: '100%',
    overflow: 'auto',
    backgroundColor: '#F5F5F5',
    padding: `${theme.spacing(3)} ${theme.spacing(4)}`,
  },
  progress: {
    marginTop: theme.spacing(2),
    height: '10px',
    borderRadius: '5px',
    backgroundColor: '#E0E0E0',
    '& .MuiLinearProgress-bar': {
      backgroundColor: ACCENT_COLOR,
      borderRadius: '5px',
    },
  },
  progressButton: {
    width: '100%',
    backgroundColor: ACCENT_COLOR,
    color: 'white',
    '&:hover': {
      backgroundColor: ACCENT_COLOR,
    },
  },
}));

export const GettingStarted: React.FC = () => {
  const classes = useStyles();
  const completedSteps = 0;

  const getProgressPercentage = (stepsComplete: number, stepCount: number): number => {
    if (!stepsComplete || !stepCount) return 2;
    return (stepsComplete / stepCount) * 100;
  };

  const gettingStartedSteps = [
    {
      id: 1,
      title: 'Try out your new site',
      description: 'We already created it for you, using the catalog from Eagle Carports.',
    },
    {
      id: 2,
      title: 'Set your site logo',
      description: 'Customers will see your brand image on the site.',
    },
    {
      id: 3,
      title: 'Set your site colors',
      description: 'Choose colors to match with your site’s brand.',
    },
    {
      id: 4,
      title: 'Create your first test building',
      description: 'Configure a building and save it.',
    },
    {
      id: 5,
      title: 'Check out your leads list',
      description: 'You just made a lead.',
    },
    {
      id: 6,
      title: 'Set up emails and contracts',
      description: 'Make sure your communication with the buyer is how you like it.',
    },
    {
      id: 7,
      title: 'Connect to your own domain',
      description: 'Set up the site so it comes from amazingsheds.com',
    },
    {
      id: 8,
      title: 'Set up payments',
      description: 'Receive payments for your deposits with card or bank transfer from buyers.',
    },
    {
      id: 9,
      title: 'Calculate sales tax (optional)',
      description: 'Automatically calculate sales tax by zip code using our ZipTax integration (additional fee)',
    },
  ];

  const totalSteps = gettingStartedSteps.length;

  return (
    <div id="get-started-root" className={classes.root}>
      <Stack direction="row" alignItems="flex-start" spacing={6} sx={{ width: '100%' }}>
        <Stack direction="column" spacing={2} sx={{ width: '500px' }}>
          <Stack direction="column" spacing={1}>
            <Typography variant="h6">Let’s get your site up and running.</Typography>
            <LinearProgress
              className={classes.progress}
              variant="determinate"
              value={getProgressPercentage(completedSteps, totalSteps)}
            />
            <Typography variant="subtitle2" sx={{ mt: 2, color: 'text.secondary' }}>
              {completedSteps} of {totalSteps} steps completed
            </Typography>
          </Stack>
          <Stack direction="column" spacing={2}>
            {gettingStartedSteps.map((step, index) => (
              <Card key={step.id} elevation={4} sx={{ marginTop: '12px', padding: 2 }}>
                <Stack direction="row" alignItems="center" spacing={3}>
                  <Grid container spacing={2} sx={{ width: '100%' }}>
                    <Grid size="auto">
                      <Stack direction="row" alignItems="center" sx={{ position: 'relative', height: '100%' }}>
                        <Stack sx={{ position: 'relative' }} alignContent="center" alignItems="center">
                          <img
                            alt="dashedCircle"
                            src={dashedCircle}
                            style={{ position: 'relative', zIndex: 1, width: '40px', height: '40px', margin: 'auto' }}
                          />
                          <Typography
                            variant="body1"
                            sx={{
                              color: ACCENT_COLOR,
                              fontWeight: 'bold',
                              position: 'absolute',
                              top: '50%',
                              left: '50%',
                              transform: 'translate(-50%, -50%)',
                              zIndex: 2,
                            }}
                          >
                            {index + 1}
                          </Typography>
                        </Stack>
                      </Stack>
                    </Grid>
                    <Grid size="grow">
                      <Stack direction="column" alignItems="left" spacing={1}>
                        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                          {step.title}
                        </Typography>
                        <Typography variant="body2">{step.description}</Typography>
                      </Stack>
                    </Grid>
                    <Grid size={3}>
                      <Stack direction="row" alignItems="center" alignContent="center" sx={{ height: '100%' }}>
                        <Button variant="contained" className={classes.progressButton}>
                          {index === 0 ? 'View' : 'Go'}
                        </Button>
                      </Stack>
                    </Grid>
                  </Grid>
                </Stack>
              </Card>
            ))}
          </Stack>
        </Stack>
        <Stack direction="column" spacing={1} sx={{ heigh: '100%' }}>
          <Typography variant="h6">Learn more about your IdeaRoom site.</Typography>
          <Stack direction="column" spacing={1}>
            <Stack direction="row" spacing={2}>
              <Card elevation={2} sx={{ width: '296px' }}>
                <CardMedia
                  alt="tutorial-1"
                  height="140"
                  component="img"
                  src={deleteMe1}
                  sx={{ backgroundColor: '#F5F5F5' }}
                />
                <CardContent>
                  <Stack direction="row" spacing={2}>
                    <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" sx={{ width: 24, height: 24 }} />
                    <Stack direction="column" spacing={0}>
                      <Typography variant="body1" component="div" sx={{ fontWeight: 'bold' }}>
                        Welcome to IdeaRoom
                      </Typography>
                      <Stack direction="row" spacing={1}>
                        <Typography variant="body2" sx={{ color: 'text.secondary', textDecoration: 'underline' }}>
                          Onboarding
                        </Typography>
                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                          • 3:24
                        </Typography>
                      </Stack>
                      <Stack>
                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                          by Jordan Miller, IdeaRoom
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                </CardContent>
              </Card>

              <Card elevation={2} sx={{ width: '296px' }}>
                <CardMedia
                  alt="tutorial-2"
                  height="140"
                  component="img"
                  src={deleteMe2}
                  sx={{ backgroundColor: '#F5F5F5' }}
                />
                <CardContent>
                  <Stack direction="row" spacing={2}>
                    <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" sx={{ width: 24, height: 24 }} />
                    <Stack direction="column" spacing={0}>
                      <Typography variant="body1" component="div" sx={{ fontWeight: 'bold' }}>
                        Boosting conversion on your site
                      </Typography>
                      <Stack direction="row" spacing={1}>
                        <Typography variant="body2" sx={{ color: 'text.secondary', textDecoration: 'underline' }}>
                          Marketing
                        </Typography>
                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                          • 3:24
                        </Typography>
                      </Stack>
                      <Stack>
                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                          by Elaine Andersen, IdeaRoom
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                </CardContent>
              </Card>
            </Stack>
            <Typography variant="body2" sx={{ mt: 3, color: 'text.secondary' }}>
              12 videos and articles
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </div>
  );
};
