import { AnyAction, ThunkDispatch, createListenerMiddleware } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import AsyncRetry from 'async-retry';
import { AppState } from '../types/AppState';
import {
  deleteFileFromS3,
  getLogosAndWatermarks,
  setImageProcessing,
  setVendorEmailLogos,
  setVendorLogos,
  setVendorWatermarks,
  uploadEmailLogoImage,
  uploadLogoImage,
  uploadWatermarkImage,
} from '../ducks/vendorDataSlice';
import { S3Buckets } from '../constants/S3';
import { s3Api } from '../services/s3Api';
import { generateFileName, removeFileExtension, toBase64 } from '../utils/fileUtils';
import { getVendorFromClientId, isCarportView } from '../utils/clientIdUtils';
import { extractErrorProps } from '../utils/errorUtils';
import { SITE_DETAIL_TABLE } from '../constants/ClientData';
import { SiteDetailDataFields } from '../constants/VendorData';
import { updateSiteDetails } from './siteDetailsThunk';
import { unknownGroup } from '../constants/Group';

const MAX_IMAGE_SIZE = 3000000;

const defaultErrorMessage = 'Something went wrong. Please try again.';
const defaultImageErrorMessage = 'Something went wrong. Please try again with a smaller image or `.png` file.';

export const vendorDataListener = createListenerMiddleware<AppState>();

// Get the logos and watermarks from S3
const getVendorLogosAndWatermarks = async (
  clientId: string,
  groupId: string,
  dispatch: ThunkDispatch<AppState, unknown, AnyAction>,
) => {
  const { data: { files = [] } = {} } = await dispatch(
    s3Api.endpoints.getAssetFiles.initiate(
      {
        groupId,
        clientId,
        bucket: S3Buckets.VendorAssets,
      },
      // Force refetches because assets may have changed.
      { forceRefetch: true },
    ),
  );

  const vendorLogos = files.filter((file) => file.includes('logo'));
  dispatch(setVendorLogos(vendorLogos));

  const vendorEmailLogos = files.filter((file) => file.includes('email'));
  dispatch(setVendorEmailLogos(vendorEmailLogos));

  const vendorWatermarks = files.filter((file) => file.includes('watermark'));
  dispatch(setVendorWatermarks(vendorWatermarks));
};

vendorDataListener.startListening({
  actionCreator: getLogosAndWatermarks,
  effect: async (action, { dispatch, getState }) => {
    const {
      clientData: { clientId },
      currentUser: { group: { groupId } = unknownGroup },
    } = getState();

    await getVendorLogosAndWatermarks(clientId, groupId, dispatch);
  },
});

const uploadImageAssetEffect = async (
  dispatch: ThunkDispatch<AppState, unknown, AnyAction>,
  groupId: string,
  clientId: string,
  image: Blob,
  prefix: string,
) => {
  try {
    dispatch(setImageProcessing(true));

    // Create file name for new logo image
    const name = generateFileName(clientId, image, prefix);

    // Convert logo image to Base64 string
    const base64 = (await toBase64(image).catch((e) => {
      // eslint-disable-next-line no-console
      console.error('Failed to upload file:', e);
      throw new Error('Failed to upload image. Please try again.');
    })) as string;

    // Call S3 endpoint to upload image to S3
    const path = `${isCarportView(clientId) ? `carportview/` : ''}${getVendorFromClientId(clientId)}/`;

    // Get file extention from file name
    const fileExtension = name.split('.').pop();

    // Based on the fileExtension, set the content type
    const contentType = fileExtension === 'png' ? 'image/png' : 'image/jpeg';

    await dispatch(
      s3Api.endpoints.uploadFile.initiate({
        groupId,
        bucket: S3Buckets.VendorAssets,
        path,
        file: {
          name,
          content: base64,
          contentType,
        },
      }),
    );

    // Get the new latest list of files
    dispatch(getLogosAndWatermarks());
  } catch (error) {
    const { errorMessage = defaultImageErrorMessage } = extractErrorProps(error);
    toast.error(errorMessage);
  } finally {
    dispatch(setImageProcessing(false));
  }
};

vendorDataListener.startListening({
  actionCreator: uploadLogoImage,
  effect: async (action, { dispatch, getState }) => {
    const state = getState();
    const { clientId } = state.clientData;
    const { group: { groupId } = unknownGroup } = state.currentUser;
    const { image } = action.payload;

    await uploadImageAssetEffect(dispatch, groupId, clientId, image, 'logo-');
  },
});

vendorDataListener.startListening({
  actionCreator: uploadEmailLogoImage,
  effect: async (action, { dispatch, getState }) => {
    const state = getState();
    const { clientId } = state.clientData;
    const { group: { groupId } = unknownGroup } = state.currentUser;
    const { image } = action.payload;

    await uploadImageAssetEffect(dispatch, groupId, clientId, image, 'email-');
  },
});

// uploadWatermarkImage
vendorDataListener.startListening({
  actionCreator: uploadWatermarkImage,
  effect: async (action, { dispatch, getState }) => {
    const state = getState();
    const { clientId } = state.clientData;
    const { currentUser: { group: { groupId } = unknownGroup } = {} } = state;
    const { image, data: rowData } = action.payload;

    try {
      dispatch(setImageProcessing(true));

      if (image.size > MAX_IMAGE_SIZE) {
        throw new Error('Image size is too large. Please upload an image smaller than 3MB.');
      }

      // Create file name for new logo image
      const name = generateFileName(clientId, image);

      // Convert watermark image to Base64 string
      const base64 = (await toBase64(image).catch((e) => {
        // eslint-disable-next-line no-console
        console.error('Failed to upload file:', e);
        throw new Error('Failed to upload image. Please try again.');
      })) as string;

      // Get file extention from file name
      const fileExtension = name.split('.').pop();

      // Based on the fileExtension, set the content type
      const contentType = fileExtension === 'png' ? 'image/png' : 'image/jpeg';

      // Upload watermark image to watermark bucket in the /in folder
      await dispatch(
        s3Api.endpoints.uploadFile.initiate({
          groupId,
          bucket: S3Buckets.Watermarks,
          path: 'in/',
          file: {
            name,
            content: base64,
            contentType,
          },
        }),
      );

      // Watermark services converts all files to png, remove extension to ensure .png extension
      const fileName = `${name.replace(/\.[^/.]+$/, '')}.png`;

      // Watermark service creates 2 files with prefixes: 'watermark-dark-' and 'watermark-light-'
      const darkFileName = `watermark-dark-${fileName}`;
      const lightFileName = `watermark-light-${fileName}`;

      // Check for converted watermark image in the output folder
      // Retry until they appear or give up after 8 retries
      // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
      await new Promise((resolve, reject) => {
        AsyncRetry(
          async (bail) => {
            const watermarkUrl = await dispatch(
              s3Api.endpoints.getFileUrl.initiate({
                groupId,
                bucket: S3Buckets.Watermarks,
                path: `out/${darkFileName}`,
                displayToastOnError: false,
              }),
            )
              .unwrap()
              .catch((e) => {
                throw new Error('Watermark file not ready yet.');
              });

            if (!watermarkUrl || Object.keys(watermarkUrl).length === 0) {
              throw new Error('Failed to find watermark files in S3.');
            }

            resolve('Successfully created watermarks');
          },
          {
            retries: 8,
            factor: 2,
            maxTimeout: 51200,
            randomize: false,
          },
        ).catch((e) => {
          reject(new Error('Failed to find watermark files in S3 after all retries.'));
        });
      }).catch((e) => {
        console.error('vendorDataListener.addWatermark - Failed with error:', e);
        throw new Error('Failed to upload watermark. Please try again with a smaller image.');
      });

      // Copy the objects over to destination bucket
      const path = `${isCarportView(clientId) ? `carportview/` : ''}${getVendorFromClientId(clientId)}/`;
      const darkFilePath = `${path}${darkFileName}`;
      const lightFilePath = `${path}${lightFileName}`;

      await dispatch(
        s3Api.endpoints.copyFile.initiate({
          groupId,
          sourceBucket: S3Buckets.Watermarks,
          sourceFilePath: `out/${darkFileName}`,
          destinationBucket: S3Buckets.VendorAssets,
          destinationFilePath: darkFilePath,
        }),
      );

      await dispatch(
        s3Api.endpoints.copyFile.initiate({
          groupId,
          sourceBucket: S3Buckets.Watermarks,
          sourceFilePath: `out/${lightFileName}`,
          destinationBucket: S3Buckets.VendorAssets,
          destinationFilePath: lightFilePath,
        }),
      );

      // Get the new latest list of files
      await getVendorLogosAndWatermarks(clientId, groupId, dispatch);

      // Get the latest watermark files from the state
      const {
        vendorData: { vendorWatermarks },
      } = getState();

      // Update the selected watermark
      const fileUrl = vendorWatermarks.find((file) => file.includes(removeFileExtension(name)));
      if (fileUrl) {
        const watermarkData = {
          table: SITE_DETAIL_TABLE,
          rowData,
          column: SiteDetailDataFields.Watermark,
          value: fileUrl,
          formula: undefined,
        };
        dispatch(updateSiteDetails([watermarkData]));
      }
    } catch (error) {
      const { errorMessage = defaultImageErrorMessage } = extractErrorProps(error);
      toast.error(errorMessage);
    } finally {
      dispatch(setImageProcessing(false));
    }
  },
});

// deleteFileFromS3
vendorDataListener.startListening({
  actionCreator: deleteFileFromS3,
  effect: async (action, { dispatch, getState }) => {
    const state = getState();
    const { clientId } = state.clientData;
    const { group: { groupId } = unknownGroup } = state.currentUser;
    const { path } = action.payload;

    try {
      if (clientId && path) {
        await dispatch(
          s3Api.endpoints.deleteFile.initiate({
            groupId,
            bucket: S3Buckets.VendorAssets,
            path,
          }),
        );
      }
      dispatch(getLogosAndWatermarks());
    } catch (error) {
      const { errorMessage = defaultErrorMessage } = extractErrorProps(error);
      toast.error(errorMessage);
    }
  },
});
