import { Permission } from '@idearoom/types';
import { User } from '../types/User';
import { isCurrentUserAdmin, isIdeaRoomUser } from './userUtils';
import { GroupMember } from '../types/GroupMember';

// Filter to unique permissions
export const filterUserPermissions = (permissions: string[] | undefined): string[] =>
  permissions && permissions.length > 0
    ? permissions.filter((value, index, self) => self.indexOf(value) === index)
    : [];

export const hasAdminPermissions = (permissions: string[] | undefined): boolean =>
  (permissions && (permissions.includes(Permission.Admin) || permissions.includes(Permission.SuperUser))) || false;

export const canUsePayments = (user: User | GroupMember, groupId: string): boolean => {
  const isIdeaRoom = isIdeaRoomUser(user);
  return (
    isIdeaRoom ||
    (['DakotaStorageBuildings', 'NewEnglandOutdoorsSheds&Gazebos', 'FancyNerdSheds', 'FancyNerdCarports'].some(
      (group) => group === groupId,
    ) &&
      isCurrentUserAdmin(user))
  );
};
