/* eslint-disable react-hooks/rules-of-hooks */

import { OnlyExistingKeys } from '../types/Repo';
import { useAppSelector } from '../hooks';
import { unknownGroup } from '../constants/Group';
import { isIdeaRoomGroup } from '../utils/userUtils';
import { PaymentOrder } from '../types/PaymentOrder';
import { Order } from '../types/Order';
import { useGetOrdersQuery } from '../services/leadApi';
import { getRequestHeader } from '../utils/apiUtils';

const EMPTY_ORDERS_REFERENCE: Readonly<PaymentOrder[]> = [];

type UseOrderRepoReturn<UseOrdersType> = OnlyExistingKeys<{
  orders: UseOrdersType extends true ? Order[] : never;
  isLoadingOrders: UseOrdersType extends true ? boolean : never;
}>;

export const useOrderRepo = <UseOrdersType extends boolean = false>({
  useOrders,
}: {
  useOrders?: UseOrdersType;
} = {}): UseOrderRepoReturn<UseOrdersType> => {
  const { groupId = '', configurators: groupConfigurators = [] } = useAppSelector(
    (state) => state.currentUser.group || unknownGroup,
  );
  const { systemGroupOrderConfigurators = [] } = useAppSelector((state) => state.systemGroupOrderConfigurators);
  const { dateRangeFilter: { startDate = undefined, endDate = undefined } = {} } = useAppSelector(
    (state) => state.muiDataGrid,
  );

  const result: any = {};
  if (useOrders) {
    const configurators = (
      isIdeaRoomGroup(groupId)
        ? systemGroupOrderConfigurators
        : groupConfigurators.map((c) => ({ configurator: c.key, vendor: c.vendor }))
    )
      .map((c) => `${c.configurator}-${c.vendor}`)
      .join(',');
    const orderRequest = {
      options: {
        queryStringParameters: {
          configurators,
          startDate: startDate?.toISOString(),
          endDate: endDate?.toISOString(),
        },
        headers: getRequestHeader({ groupId }),
      },
    };

    const skip = !configurators.length;
    const { data: { orders = EMPTY_ORDERS_REFERENCE } = {}, isFetching: isLoadingOrders } = useGetOrdersQuery(
      { orderRequest },
      {
        skip,
        refetchOnMountOrArgChange: true,
      },
    );
    result.orders = orders;
    result.isLoadingOrders = isLoadingOrders;
  }

  return result;
};
