import React from 'react';
import { makeStyles } from '@mui/styles';
import { Typography, Step, StepLabel, StepContent, StepContentProps, Theme } from '@mui/material';

const useStyles = makeStyles((_: Theme) => ({
  message: {
    fontSize: '14px',
    fontWeight: 400,
    color: '#323B4BB2',
    // Add space between paragraphs
    '& > div:not(:last-child)': {
      margin: '0px 0px 6px 0px',
    },
    '& > div:last-child': {
      margin: '0px 0px 12px 0px',
    },
  },
  stepContent: {
    border: 'none',
    padding: '0px',
    margin: '0px 0px 0px 16px',
  },
}));

type Props = StepContentProps & { index: number; header: string; message?: string; expanded: boolean };

export const ClientSignUpStep: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const { children, index, header, message, expanded } = props;

  return (
    <Step expanded={expanded}>
      <StepLabel
        slots={{
          label: Typography,
        }}
        slotProps={{
          label: {
            style: { fontWeight: 'bold', fontSize: '14px', color: '#323B4B' },
          },
        }}
        style={{ fontWeight: 'bold' }}
      >
        {`${index + 1}. ${header}`}
      </StepLabel>
      <StepContent className={classes.stepContent}>
        {message && <Typography className={classes.message} dangerouslySetInnerHTML={{ __html: message }} />}
        {children}
      </StepContent>
    </Step>
  );
};
