import { createApi } from '@reduxjs/toolkit/query/react';
import { API_NAMES } from '../constants/App';
import { amplifyAPIBaseQuery } from '../utils/apiUtils';
import { Configurator } from '../types/Configurator';
import { GroupData, GroupSettings } from '../types/Group';
import { GroupCacheTagType } from '../constants/Group';

export const groupApi = createApi({
  reducerPath: 'groupApi',
  refetchOnFocus: true,
  refetchOnReconnect: true,
  tagTypes: [GroupCacheTagType.Group, GroupCacheTagType.Groups],
  baseQuery: amplifyAPIBaseQuery({
    apiName: API_NAMES.API_PUBLIC,
    baseUrl: '/v1/internal/group',
  }),
  endpoints: (builder) => ({
    /**
     * Gets a group by its groupId
     */
    getGroup: builder.query<GroupData, { groupId: string; displayToastOnError?: boolean }>({
      query: ({ groupId, displayToastOnError = false }) => ({
        url: `/${groupId}`,
        method: 'get',
        displayToastOnError,
      }),
      providesTags: (result, error, { groupId }) => (result ? [{ type: GroupCacheTagType.Group, id: groupId }] : []),
    }),

    /**
     * Lists all groups
     */
    getGroups: builder.query<GroupData[], void>({
      query: () => ({
        url: `s/all`,
        method: 'get',
      }),
      providesTags: (result) => (result ? [GroupCacheTagType.Groups] : []),
    }),

    /**
     * Lists all groups for a user
     */
    getUserGroups: builder.query<{ groups: GroupData[] }, void>({
      query: () => ({
        url: `s/user`,
        method: 'get',
      }),
      providesTags: (result) => (result ? [GroupCacheTagType.Groups] : []),
    }),

    /**
     * Creates a group
     */
    createGroup: builder.mutation<
      GroupData,
      {
        groupId: string;
        groupName: string;
      }
    >({
      query: ({ groupId, groupName }) => ({
        url: `/${groupId}`,
        method: 'post',
        init: {
          body: {
            groupName,
          },
        },
      }),
      invalidatesTags: [GroupCacheTagType.Groups],
    }),

    /**
     * Updates a group
     */
    updateGroup: builder.mutation<
      GroupData,
      {
        groupId: string;
        groupName: string;
      }
    >({
      query: ({ groupId, groupName }) => ({
        url: `/${groupId}`,
        method: 'put',
        init: {
          body: {
            groupName,
          },
        },
      }),
      invalidatesTags: (result, error, { groupId }) => [{ type: GroupCacheTagType.Group, id: groupId }],
    }),

    /**
     * Deletes a group
     */
    deleteGroup: builder.mutation<
      void,
      {
        groupId: string;
      }
    >({
      query: ({ groupId }) => ({
        url: `/${groupId}`,
        method: 'del',
      }),
      invalidatesTags: (result, error, { groupId }) => [
        { type: GroupCacheTagType.Group, id: groupId },
        GroupCacheTagType.Groups,
      ],
    }),

    /**
     * Add configurator to group
     */
    addConfiguratorToGroup: builder.mutation<
      void,
      {
        groupId: string;
        configurator: Configurator;
      }
    >({
      query: ({ groupId, configurator }) => ({
        url: `/${groupId}/configurators`,
        method: 'post',
        init: {
          body: {
            configurator,
          },
        },
      }),
      invalidatesTags: (result, error, { groupId }) => [{ type: GroupCacheTagType.Group, id: groupId }],
    }),

    /**
     * Remove configurator from group
     */
    removeConfiguratorFromGroup: builder.mutation<
      void,
      {
        groupId: string;
        configurator: Configurator;
      }
    >({
      query: ({ groupId, configurator }) => ({
        url: `/${groupId}/configurators`,
        method: 'del',
        init: {
          body: {
            configurator,
          },
        },
      }),
      invalidatesTags: (result, error, { groupId }) => [{ type: GroupCacheTagType.Group, id: groupId }],
    }),

    /**
     * Update group settings
     */
    updateGroupSettings: builder.mutation<
      void,
      {
        groupId: string;
        settings: GroupSettings;
      }
    >({
      query: ({ groupId, settings }) => ({
        url: `/${groupId}/settings`,
        method: 'put',
        init: {
          body: {
            settings,
          },
        },
      }),
      invalidatesTags: (result, error, { groupId }) => [{ type: GroupCacheTagType.Group, id: groupId }],
    }),
  }),
});

export const {
  useGetGroupQuery,
  useGetGroupsQuery,
  useCreateGroupMutation,
  useUpdateGroupMutation,
  useDeleteGroupMutation,
  useAddConfiguratorToGroupMutation,
  useRemoveConfiguratorFromGroupMutation,
  useUpdateGroupSettingsMutation,
} = groupApi;
