/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import { Box, CircularProgress, Paper, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { navigate } from 'hookrouter';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import IdeaRoomLogo from '../images/idearoom.svg';
import { useGetSubscriptionDataByIdQuery, useSharedAddClientMutation } from '../services/clientApi';
import { useAppDispatch, useAppSelector } from '../hooks';
import { ClientSignUpFormFields, SignInFormFields } from '../constants/FormFields';
import { AppRoutes } from '../constants/AppRoutes';
import { Forms } from '../constants/Forms';
import { I18nKeys } from '../constants/I18nKeys';
import { LocalStorage } from '../constants/LocalStorage';
import { signedIn } from '../ducks/currentUserSlice';
import { ClientSignUpForm, FormData } from './ClientSignUpForm';

const CenteredPaper = styled(Paper)(({ theme }) => ({
  width: 'calc(100% - 400px)',
  minWidth: '70%',
  height: 'calc(100% - 100px)',
}));

export const ClientSignUp: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const subscriptionId = useAppSelector((state) => state.signUp.subscriptionId || '');

  const [loading, setLoading] = React.useState<boolean>(true);

  const {
    data: {
      customer: { organization: company = '', email = '' } = {},
      product: { industry = '', type = '' } = {},
      authenticated,
    } = {},
    isUninitialized: isUninitializedSubscriptionData,
    isLoading: isLoadingSubscriptionData,
  } = useGetSubscriptionDataByIdQuery(subscriptionId, {
    skip: !subscriptionId,
  });

  const [addClient] = useSharedAddClientMutation();

  useEffect(() => {
    if (!isUninitializedSubscriptionData && !isLoadingSubscriptionData) {
      if (authenticated) {
        setLoading(false);
        return;
      }
      // User's subscription is not authenticated, redirect to sign in
      toast.error(t(I18nKeys.ClientSignUpSubscriptionInvalid));
      navigate(AppRoutes.SignIn);
    }
  }, [isUninitializedSubscriptionData, isLoadingSubscriptionData, authenticated, t]);

  return (
    <Box
      sx={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {loading && <CircularProgress style={{ alignSelf: 'center' }} color="primary" />}
      {!loading && (
        <CenteredPaper elevation={0}>
          <Box sx={{ textAlign: 'center' }}>
            <img alt="IdeaRoom Logo" src={IdeaRoomLogo} />
          </Box>
          <Typography variant="subtitle1" style={{ fontWeight: 'bold', color: '#323B4B' }} gutterBottom>
            Let&#39;s create your 3D configurator site...
          </Typography>
          <ClientSignUpForm
            onSubmit={async ({
              [ClientSignUpFormFields.Industry]: userIndustry,
              [ClientSignUpFormFields.Supplier]: userSupplier,
              [ClientSignUpFormFields.Email]: userEmail,
              [ClientSignUpFormFields.Password]: userPassword,
            }: FormData) => {
              const {
                client: { groupId },
              } = await addClient({
                company: company || '',
                email: userEmail,
                supplier: userSupplier?.id,
                industry: userIndustry,
                type,
                subscriptionId,
              }).unwrap();
              localStorage.setItem(LocalStorage.SelectedGroupId, groupId);

              if (userPassword) {
                await new Promise((resolve, reject): void => {
                  dispatch({
                    type: `${Forms.SignIn}_SUBMIT`,
                    values: {
                      [SignInFormFields.Email]: userEmail,
                      [SignInFormFields.Password]: userPassword,
                    },
                    resolve,
                    reject,
                  });
                });
              } else {
                // TODO: Either add back OTP or use a password for existing users here
                dispatch(signedIn());
              }
            }}
            initialValues={{
              [ClientSignUpFormFields.Industry]: industry,
              [ClientSignUpFormFields.Email]: email,
            }}
          />
        </CenteredPaper>
      )}
    </Box>
  );
};
