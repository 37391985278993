import { SALESVIEW } from './App';
import { ClientDataType } from './ClientDataType';
import { SystemGroups } from './SystemGroups';
import { ConfiguratorFormFields } from './FormFields';
import { I18nKeys } from './I18nKeys';
import { ConfiguratorFormFields as ConfiguratorFormFieldsType } from '../types/Configurator';
import { IDEAROOM_CLIENT_ID } from './ClientId';

/**
 * Default configurator for passing to the ConfiguratorAppBar to get a `SalesView` selection
 */
export const salesViewConfigurator = {
  clientId: SALESVIEW,
  name: 'SalesView',
  key: SALESVIEW,
  vendor: SALESVIEW,
  vendorData: { clientId: SALESVIEW },
  updating: false,
};

/**
 * Default configurator for IdeaRoom group
 */
export const referenceConfigurator = {
  key: SystemGroups.IdeaRoom.toLowerCase(),
  name: 'Reference Data',
  suppliers: [],
  clientId: IDEAROOM_CLIENT_ID,
  updating: false,
  vendor: ClientDataType.Reference,
  vendorData: {
    clientId: IDEAROOM_CLIENT_ID,
    vendor: {
      name: 'Reference Data',
    },
  },
};

export enum Product {
  Dealer = 'DEALER',
  Supplier = 'SUPPLIER',
}

export enum Industry {
  Sheds = 'sheds',
  Carports = 'carports',
}

export enum ConfiguratorFormFieldType {
  Text = 'text',
  Select = 'select',
  RadioSelect = 'radioSelect',
}

export enum ConfiguratorFormType {
  AddGroup = 'addGroup',
  AddGroupWithDefault = 'addGroupWithDefault',
  EditGroup = 'editGroup',
  AddNewConfigurator = 'addNewConfigurator',
  AddExistingConfigurator = 'addExistingConfigurator',
  EditConfigurator = 'editConfigurator',
}

export const SUPPLIER_DATA_TEMPLATE_NONE_KEY = 'none';

export const configuratorFormFields: ConfiguratorFormFieldsType[] = [
  {
    type: ConfiguratorFormFieldType.Text,
    fieldName: ConfiguratorFormFields.Name,
    required: true,
    getXS: () => 12,
    label: I18nKeys.ConfiguratorFormFieldGroupName,
  },
  {
    type: ConfiguratorFormFieldType.Select,
    fieldName: ConfiguratorFormFields.Industry,
    getXS: (form) => ([ConfiguratorFormType.AddExistingConfigurator].includes(form) ? 12 : 6),
    selectOptions: [
      { value: Industry.Sheds, label: I18nKeys.ConfiguratorFormFieldConfiguratorSheds },
      { value: Industry.Carports, label: I18nKeys.ConfiguratorFormFieldConfiguratorCarports },
    ],
    selectLabel: I18nKeys.ConfiguratorFormFieldConfigurator,
  },
  {
    type: ConfiguratorFormFieldType.Select,
    fieldName: ConfiguratorFormFields.ExistingKey,
    getXS: () => 12,
    selectOptions: [],
    selectLabel: I18nKeys.ConfiguratorFormFieldKey,
  },
  {
    type: ConfiguratorFormFieldType.Text,
    fieldName: ConfiguratorFormFields.Key,
    required: true,
    getXS: (form) => ([ConfiguratorFormType.EditConfigurator].includes(form) ? 12 : 6),
    label: I18nKeys.ConfiguratorFormFieldKey,
    disabled: (form) => [ConfiguratorFormType.EditConfigurator].includes(form),
  },
  {
    getXS: () => 12,
    // Only show if creating new group
    getHelperLabel: (form) =>
      [ConfiguratorFormType.AddGroupWithDefault].includes(form) ? I18nKeys.ConfiguratorFormConfiguratorHelp : undefined,
  },
  {
    type: ConfiguratorFormFieldType.Text,
    fieldName: ConfiguratorFormFields.Subdomain,
    required: true,
    getXS: () => 6,
    label: I18nKeys.ConfiguratorFormFieldSubdomain,
    disabled: (form) => [ConfiguratorFormType.EditConfigurator].includes(form),
  },
  {
    type: ConfiguratorFormFieldType.Text,
    fieldName: ConfiguratorFormFields.Domain,
    required: true,
    getXS: () => 6,
    label: I18nKeys.ConfiguratorFormFieldDomain,
    disabled: (form) => [ConfiguratorFormType.EditConfigurator].includes(form),
  },
  {
    type: ConfiguratorFormFieldType.RadioSelect,
    fieldName: ConfiguratorFormFields.Supplier,
    required: true,
    getXS: () => 12,
    radioGroup: ConfiguratorFormFields.Type,
    radioOption: Product.Dealer,
    radioLabel: I18nKeys.ConfiguratorFormFieldDealer,
    selectOptions: [],
    selectLabel: I18nKeys.ConfiguratorFormFieldDefaultSupplier,
  },
  {
    type: ConfiguratorFormFieldType.RadioSelect,
    fieldName: ConfiguratorFormFields.SupplierDataTemplate,
    required: true,
    getXS: () => 12,
    radioGroup: ConfiguratorFormFields.Type,
    radioOption: Product.Supplier,
    radioLabel: I18nKeys.ConfiguratorFormFieldSupplier,
    selectOptions: [],
    selectLabel: I18nKeys.ConfiguratorFormFieldCopyFromDataset,
    getHelperLabel: () => I18nKeys.ConfiguratorFormSupplierDataHelp,
  },
  {
    getXS: () => 12,
    label: I18nKeys.ConfiguratorFormVendorDataHelp,
  },
  {
    type: ConfiguratorFormFieldType.Select,
    fieldName: ConfiguratorFormFields.VendorDataTemplate,
    selectLabel: I18nKeys.ConfiguratorFormFieldCopyFromDataset,
    required: true,
    getXS: () => 12,
    selectOptions: [],
  },
];
