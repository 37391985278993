// 'react-app-polyfill/ie11' must be first
// 'react-app-polyfill/stable' must be second
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { Amplify } from 'aws-amplify';
import { getCurrentUser } from '@aws-amplify/auth';
import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { reducer as formReducer } from 'redux-form';
import createSagaMiddleware from 'redux-saga';
import { LicenseManager } from 'ag-grid-enterprise';
import { usePath } from 'hookrouter';
import { App } from './App';
import { config } from './config/config';
import { confirmationReducer } from './ducks/confirmation';
import { clientDataSlice } from './ducks/clientDataSlice';
import { pricingSlice } from './ducks/pricingSlice';
import { vendorDataSlice } from './ducks/vendorDataSlice';
import { dialogSlice } from './ducks/dialogSlice';
import { groupReducer } from './ducks/group';
import { groupsReducer } from './ducks/groups';
import { menuReducer } from './ducks/menu';
import { notificationDialogReducer } from './ducks/notificationDialog';
import { ordersReducer } from './ducks/orders';
import { searchReducer } from './ducks/search';
import { secondaryActionsReducer } from './ducks/secondaryActions';
import { settingsReducer } from './ducks/settings';
import { setSubscriptionId, signUpSlice } from './ducks/signUpSlice';
import { systemGroupOrderConfiguratorsReducer } from './ducks/systemGroupOrderConfigurators';
import { rootSaga } from './saga/rootSaga';
import { googleAnalyticsMiddleware } from './middleware/googleAnalyticsMiddleware';
import { googleAnalyticsInstance } from './analytics/googleAnalyticsInstance';
import { API_NAMES, SALESVIEW } from './constants/App';
import { clientDataApi } from './services/clientDataApi';
import { clientDataListener } from './middleware/clientDataListener';
import { integrationListener } from './middleware/integrationListener';
import { s3Api } from './services/s3Api';
import { vendorDataListener } from './middleware/vendorDataListener';
import { pricingListener } from './middleware/pricingListener';
import { domainApi } from './services/domainApi';
import { sesApi } from './services/sesApi';
import { userApi } from './services/userApi';
import { dealerApi } from './services/dealerApi';
import { groupApi } from './services/groupApi';
import { integrationApi } from './services/integrationApi';
import { notificationApi } from './services/notificationApi';
import { leadApi } from './services/leadApi';
import { salesRepApi } from './services/salesRepApi';
import { settingApi } from './services/settingApi';
import { vendorDataApi } from './services/vendorDataApi';
import { clientApi } from './services/clientApi';
import { pricingApi } from './services/pricingApi';
import { viewerSlice } from './ducks/viewerSlice';
import { currentUserSlice, signOutAction, signedIn } from './ducks/currentUserSlice';
import { userListener } from './middleware/userListener';
import { viewerListener } from './middleware/viewerListener';
import { muiDataGridSlice } from './ducks/muiDataGridSlice';
import { integrationSlice } from './ducks/integrationSlice';
import { dealersSlice } from './ducks/dealersSlice';
import { isAnonymousRoute } from './utils/appRouteUtils';
import { AppRoutes } from './constants/AppRoutes';
import { SessionStorage } from './constants/SessionStorage';

// Set AG Grid license key
LicenseManager.setLicenseKey(
  'Using_this_{AG_Grid}_Enterprise_key_{AG-059147}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{IdeaRoom}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{SalesView}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{SalesView}_need_to_be_licensed___{SalesView}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{11_June_2025}____[v3]_[01]_MTc0OTU5NjQwMDAwMA==b2ec4e3395786d5102307196fb4cc972',
);

/* global sessionStorage */

/**
 * Check for any query string parameters.
 * If any exist, save them to sessionStorage.
 */
const queryParams = new URLSearchParams(window.location.search);
if (queryParams) {
  queryParams.forEach((value, key) => sessionStorage.setItem(key, decodeURIComponent(value)));
}

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: config.cognito.USER_POOL_ID || 'UNKNOWN',
      identityPoolId: config.cognito.IDENTITY_POOL_ID || 'UNKNOWN',
      userPoolClientId: config.cognito.APP_CLIENT_ID || 'UNKNOWN',
      loginWith: {
        oauth: {
          domain: config.cognito.DOMAIN || 'UNKNOWN',
          scopes: ['email', 'openid', 'profile'],
          redirectSignIn: [config.cognito.REDIRECT_SIGN_IN || 'UNKNOWN'],
          redirectSignOut: [config.cognito.REDIRECT_SIGN_OUT || 'UNKNOWN'],
          responseType: 'code' as 'code' | 'token',
        },
        email: true,
      },
    },
  },
  API: {
    REST: {
      [API_NAMES.PORTAL]: {
        region: config.apiGateway.REGION,
        endpoint: config.apiGateway.URL || 'UNKNOWN',
      },
      [API_NAMES.API_PUBLIC]: {
        region: config.apiGateway.REGION,
        endpoint: config.apiGateway.API_PUBLIC_URL || 'UNKNOWN',
      },
    },
  },
});

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: {
    [clientApi.reducerPath]: clientApi.reducer,
    [clientDataApi.reducerPath]: clientDataApi.reducer,
    [dealerApi.reducerPath]: dealerApi.reducer,
    [domainApi.reducerPath]: domainApi.reducer,
    [groupApi.reducerPath]: groupApi.reducer,
    [integrationApi.reducerPath]: integrationApi.reducer,
    [notificationApi.reducerPath]: notificationApi.reducer,
    [leadApi.reducerPath]: leadApi.reducer,
    [pricingApi.reducerPath]: pricingApi.reducer,
    [s3Api.reducerPath]: s3Api.reducer,
    [salesRepApi.reducerPath]: salesRepApi.reducer,
    [settingApi.reducerPath]: settingApi.reducer,
    [sesApi.reducerPath]: sesApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [vendorDataApi.reducerPath]: vendorDataApi.reducer,
    confirmation: confirmationReducer,
    clientData: clientDataSlice.reducer,
    currentUser: currentUserSlice.reducer,
    dealers: dealersSlice.reducer,
    dialog: dialogSlice.reducer,
    menu: menuReducer,
    muiDataGrid: muiDataGridSlice.reducer,
    notificationDialog: notificationDialogReducer,
    form: formReducer,
    group: groupReducer,
    groups: groupsReducer,
    orders: ordersReducer,
    pricing: pricingSlice.reducer,
    integration: integrationSlice.reducer,
    signUp: signUpSlice.reducer,
    search: searchReducer,
    secondaryActions: secondaryActionsReducer,
    settings: settingsReducer,
    systemGroupOrderConfigurators: systemGroupOrderConfiguratorsReducer,
    vendorData: vendorDataSlice.reducer,
    viewer: viewerSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    })
      .concat(clientApi.middleware)
      .concat(clientDataApi.middleware)
      .concat(pricingApi.middleware)
      .concat(dealerApi.middleware)
      .concat(vendorDataApi.middleware)
      .concat(domainApi.middleware)
      .concat(groupApi.middleware)
      .concat(integrationApi.middleware)
      .concat(notificationApi.middleware)
      .concat(leadApi.middleware)
      .concat(s3Api.middleware)
      .concat(salesRepApi.middleware)
      .concat(settingApi.middleware)
      .concat(sesApi.middleware)
      .concat(userApi.middleware)
      .concat(sagaMiddleware)
      .concat(googleAnalyticsMiddleware)
      .concat(clientDataListener.middleware)
      .concat(integrationListener.middleware)
      .concat(vendorDataListener.middleware)
      .concat(pricingListener.middleware)
      .concat(userListener.middleware)
      .concat(viewerListener.middleware),
});

setupListeners(store.dispatch);

sagaMiddleware.run(rootSaga);

googleAnalyticsInstance.addTrackerId(config.googleAnalytics.GA_TRACKING_ID || 'G-TYHXEVX5QV');
googleAnalyticsInstance.initializeStandardExtraProps(
  config.environment.STAGE || 'development',
  SALESVIEW,
  'uknown',
  'uknown',
);

const AppWrapper = () => {
  const location = usePath();

  React.useEffect(() => {
    if (!isAnonymousRoute(location)) {
      getCurrentUser()
        .then(() => {
          store.dispatch(signedIn());
        })
        .catch((error) => {
          // Clear user state when there's no authenticated user and log the error to console
          store.dispatch(signOutAction());
          // eslint-disable-next-line no-console
          console.error(error);
        });
    }
    if (location.startsWith(AppRoutes.ClientSignUp)) {
      const subscriptionId = sessionStorage.getItem(SessionStorage.SubscriptionId) || '';
      store.dispatch(setSubscriptionId(subscriptionId));
    }
  }, [location]);

  return <App />;
};

const root = createRoot(document.getElementById('root')!); // eslint-disable-line @typescript-eslint/no-non-null-assertion

root.render(
  <Provider store={store} stabilityCheck="once" noopCheck="once">
    <AppWrapper />
  </Provider>,
);
