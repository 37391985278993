import { navigate } from 'hookrouter';
import { TFunction } from 'react-i18next';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { Branch } from '@idearoom/types';
import { QueryActionCreatorResult } from '@reduxjs/toolkit/dist/query/core/buildInitiate';
import {
  Configurator as ConfiguratorType,
  ConfiguratorFormFields as ConfiguratorFormFieldsType,
  ConfiguratorFormProps,
  ConfiguratorFormValues,
} from '../types/Configurator';
import {
  Industry,
  ConfiguratorFormFieldType,
  ConfiguratorFormType,
  Product,
  SUPPLIER_DATA_TEMPLATE_NONE_KEY,
  configuratorFormFields,
} from '../constants/Configurator';
import { ConfiguratorFormFields } from '../constants/FormFields';
import { Group } from '../types/Group';
import { clientDataApi, getClientDataCacheTag } from '../services/clientDataApi';
import { ClientDataType } from '../constants/ClientDataType';
import { ClientDataBranch } from '../constants/ClientDataBranch';
import { getVendorFromClientId, mapClientIdToProduct, mapConfiguratorToClientId } from './clientIdUtils';
import { AppRoutes } from '../constants/AppRoutes';
import { getConfiguratorUrlWithLocale } from './vendorUtils';
import { closeDialog } from '../ducks/dialogSlice';
import { I18nKeys } from '../constants/I18nKeys';
import { ClientDataCacheTagType, ClientPublishedVersions, SITE_DETAIL_TABLE } from '../constants/ClientData';
import { extractErrorProps } from './errorUtils';
import { s3Api } from '../services/s3Api';
import { Vendor } from '../types/VendorData';
import { AppState } from '../types/AppState';
import { groupApi } from '../services/groupApi';
import { salesRepApi } from '../services/salesRepApi';
import { clientApi } from '../services/clientApi';

export const getConfigWithVendor = async (
  configurator: ConfiguratorType,
  vendor: Vendor,
): Promise<ConfiguratorType> => {
  const vendorDataThemeProps = [
    'logoUrl',
    'contactBarColor',
    'contactBarTextColor',
    'selectedTextColor',
    'logoBarColor',
    'logoBarTextColor',
  ];
  const vendorDataConfigProps = [
    'name',
    'supplierKey',
    'currency',
    'useMetricUnits',
    'languages',
    'proSubscriptionEnabled',
    'proServicesBranchEnabled',
    'salesToolsEnabled',
    'dealerNetworkEnabled',
    'pricingEnabled',
  ];

  const newVendor: any = {
    smartbuildIntegrationEnabled: 'smartbuildJob' in vendor && !!vendor.smartbuildJob,
  };
  [...vendorDataThemeProps, ...vendorDataConfigProps].forEach((prop) => {
    newVendor[prop] = vendor[prop] || ((configurator.vendorData || {}).vendor || {})[prop];
  });
  const { name } = newVendor;
  const { locale = '', productionURL = '' } = vendor;
  const url = getConfiguratorUrlWithLocale(configurator.key, configurator.vendor, locale, productionURL);
  const updatedConfig = {
    ...configurator,
    url,
    name,
    vendorData: {
      ...configurator.vendorData,
      clientId: mapConfiguratorToClientId(configurator),
      vendor: newVendor,
    },
  };
  return updatedConfig;
};

export const mapDatasetConfigToClassic = (configurator: string): string => {
  if (configurator?.toLowerCase() === 'carports') return 'carportview';
  return 'shedview';
};

export const mapClassicConfigToDataset = (configurator: string): string => {
  if (configurator?.toLowerCase() === 'carportview') return 'carports';
  return 'sheds';
};

export const isValidSubdomain = (subdomain: string): boolean =>
  /^[A-Za-z0-9][A-Za-z0-9-]{0,61}[A-Za-z0-9]$/.test(subdomain);

export const isValidDomain = (domain: string): boolean =>
  /^[A-Za-z0-9][A-Za-z0-9-]{0,61}[A-Za-z0-9](\.[A-Za-z0-9-]+)*\.[A-Za-z0-9]{2,}$/.test(domain);

const formatDomain = (domain: string | undefined): string => domain?.toLowerCase().replace(/\s/g, '') || '';

const getGroupIdFromGroupName = (groupName: string) => groupName.replace(/\s/g, '');

export const validateConfiguratorForm = async (
  fields: ConfiguratorFormFieldsType[],
  values: ConfiguratorFormValues,
  dispatch: ThunkDispatch<AppState, unknown, AnyAction>,
  form: ConfiguratorFormType,
  selectedGroupId: string,
): Promise<{ [key in ConfiguratorFormFields]?: string }> => {
  const fieldsToValidate = fields
    .filter((field) => (field && !(field as any)?.disabled) || !(field as any).disabled(form))
    .map((field) => (field as any).fieldName);
  const errors: { [key: string]: string } = {};

  [
    ConfiguratorFormFields.Name,
    ConfiguratorFormFields.Key,
    ConfiguratorFormFields.ExistingKey,
    ConfiguratorFormFields.Subdomain,
    ConfiguratorFormFields.Domain,
    ConfiguratorFormFields.Industry,
    ConfiguratorFormFields.VendorDataTemplate,
    ...(values[ConfiguratorFormFields.Type] === Product.Dealer
      ? [ConfiguratorFormFields.Supplier]
      : [ConfiguratorFormFields.SupplierDataTemplate]),
  ].forEach((field) => {
    if (fieldsToValidate.includes(field) && !values[field]) {
      errors[field] = 'Required';
    }
  });

  const {
    [ConfiguratorFormFields.Name]: name,
    [ConfiguratorFormFields.Key]: key,
    [ConfiguratorFormFields.Industry]: industry,
    [ConfiguratorFormFields.Subdomain]: subdomain,
    [ConfiguratorFormFields.Domain]: domain,
  } = values;
  const groupId = getGroupIdFromGroupName(name || '');
  const clientId = `${industry === Industry.Carports ? 'carportview' : 'shedview'}-${key}`;
  const formattedSubdomain = formatDomain(subdomain);
  const formattedDomain = formatDomain(domain);

  if (
    [ConfiguratorFormType.AddGroup, ConfiguratorFormType.AddGroupWithDefault].includes(form) &&
    fieldsToValidate.includes(ConfiguratorFormFields.Name) &&
    name
  ) {
    // If creating group, check if it already exists
    try {
      const getGroupFetch = dispatch(
        groupApi.endpoints.getGroup.initiate({
          groupId,
          displayToastOnError: false,
        }) as any,
      ) as QueryActionCreatorResult<any>;
      getGroupFetch.unsubscribe();

      const { data: { group: existingGroup } = {} } = (await getGroupFetch) as { data: { group: Group } };

      if (existingGroup) {
        errors[ConfiguratorFormFields.Name] = 'This group name is already in use';
      }
    } catch (error) {
      const { errorMessage = 'Error occurred while validating group name' } = extractErrorProps(error);
      if (errorMessage !== 'Group not found') {
        errors[ConfiguratorFormFields.Name] = errorMessage;
      }
    }
  }

  if (
    [ConfiguratorFormType.EditConfigurator].includes(form) &&
    fieldsToValidate.includes(ConfiguratorFormFields.Name) &&
    name
  ) {
    // If updating name, check if an unpublished or hotfix branch exists
    try {
      const activeBranchesFetch = dispatch(
        clientDataApi.endpoints.getClientDataBranches.initiate({
          dataType: ClientDataType.Vendor,
          clientId,
          groupId: selectedGroupId,
        }),
      );
      activeBranchesFetch.unsubscribe();
      const { data = [] } = await activeBranchesFetch;
      if (
        data.some((branch) =>
          [ClientDataBranch.Hotfix, ClientDataBranch.Unpublished, ClientDataBranch.ProfessionalServices].includes(
            branch.branchType,
          ),
        )
      ) {
        errors[ConfiguratorFormFields.Name] =
          'Cannot update name while unpublished, quick update, or professional services branches exist';
      }
    } catch (error) {
      const { errorMessage = 'Error occurred while checking for open branches' } = extractErrorProps(error);
      errors[ConfiguratorFormFields.Name] = errorMessage;
    }
  }

  if (key && industry && fieldsToValidate.includes(ConfiguratorFormFields.Key)) {
    const validChars = /^[A-Za-z0-9-]*$/.test(key);
    if (!validChars) {
      errors[ConfiguratorFormFields.Key] = 'Key may only contain letters, numbers, and dashes';
    } else {
      try {
        const vendorDataVendorFetch = dispatch(
          clientDataApi.endpoints.getClientDataTableData.initiate({
            dataType: ClientDataType.Vendor,
            branch: ClientDataBranch.Main,
            clientId,
            groupId: 'IdeaRoom',
            table: 'vendor',
          }),
        );
        vendorDataVendorFetch.unsubscribe();
        const { data = [] } = await vendorDataVendorFetch;
        const [vendorData] = data;

        if (vendorData) {
          errors[ConfiguratorFormFields.Key] = 'This key is already in use';
        }
      } catch (error) {
        const { errorMessage = 'Error occurred while validating key' } = extractErrorProps(error);
        errors[ConfiguratorFormFields.Key] = errorMessage;
      }
    }
  }

  if (
    formattedSubdomain &&
    formattedDomain &&
    fieldsToValidate.includes(ConfiguratorFormFields.Subdomain) &&
    fieldsToValidate.includes(ConfiguratorFormFields.Domain)
  ) {
    const validSubdomain = isValidSubdomain(formattedSubdomain);
    if (!validSubdomain) {
      errors[ConfiguratorFormFields.Subdomain] = 'Must follow URL rules';
    }
    const validDomain = isValidDomain(formattedDomain);
    if (!validDomain) {
      errors[ConfiguratorFormFields.Domain] = 'Must follow URL rules';
    }
    if (validSubdomain && validDomain) {
      try {
        const bucket = `${groupId}-${mapClientIdToProduct(clientId).toLocaleLowerCase()}`;
        const getIndexFileURLFetch = dispatch(
          s3Api.endpoints.getFileUrl.initiate({
            groupId: 'IdeaRoom',
            bucket: 'client-cloudfront',
            path: `/${bucket}/index.html`,
            displayToastOnError: false,
          }) as any,
        ) as QueryActionCreatorResult<any>;
        getIndexFileURLFetch.unsubscribe();
        const { data: indexFileURL } = await getIndexFileURLFetch;

        if (indexFileURL) {
          const message = 'A production site already exists for this industry and client ID';
          errors[ConfiguratorFormFields.Subdomain] = message;
          errors[ConfiguratorFormFields.Domain] = message;
        }
      } catch (error) {
        const { errorMessage = 'Error occurred while production site uniqueness' } = extractErrorProps(error);
        if (errorMessage !== 'The specified bucket does not exist') {
          errors[ConfiguratorFormFields.Subdomain] = errorMessage;
          errors[ConfiguratorFormFields.Domain] = errorMessage;
        }
      }
    }
  }

  return errors;
};

export const getDefaultConfiguratorFormValues = async (
  fields: ConfiguratorFormFieldsType[],
  dispatch: ThunkDispatch<AppState, unknown, AnyAction>,
  formParams: {
    form: ConfiguratorFormType;
    group?: Group;
    configurator?: ConfiguratorType;
    t: TFunction;
    selectedGroupId: string;
  },
): Promise<ConfiguratorFormValues> => {
  const values: { [key: string]: string | boolean } = {};
  const { form, group, configurator, selectedGroupId } = formParams;
  const clientId = mapConfiguratorToClientId(configurator);

  let configuratorName = '';
  if (configurator) {
    const siteDetailFetch = dispatch(
      clientDataApi.endpoints.getClientDataTableData.initiate({
        dataType: ClientDataType.Vendor,
        branch: ClientDataBranch.Main,
        clientId,
        groupId: selectedGroupId,
        table: SITE_DETAIL_TABLE,
      }),
    );
    siteDetailFetch.unsubscribe();
    const { data = [] } = await siteDetailFetch;
    const [{ name: configuratorNameFromSiteDetail }] = data;
    configuratorName = (configuratorNameFromSiteDetail as string) || '';
  }

  fields.forEach((field, i) => {
    const { type, fieldName, radioGroup, radioOption } = field as {
      type?: ConfiguratorFormFieldType;
      fieldName?: ConfiguratorFormFields;
      radioGroup?: string;
      radioOption?: string;
    };
    switch (fieldName) {
      case ConfiguratorFormFields.Name:
        values[fieldName] = (configurator ? configuratorName : group?.groupName) || '';
        break;
      case ConfiguratorFormFields.Key:
        values[fieldName] = configurator?.vendor || '';
        values[ConfiguratorFormFields.GenerateKey] = [
          ConfiguratorFormType.AddNewConfigurator,
          ConfiguratorFormType.AddGroupWithDefault,
        ].includes(form);
        break;
      case ConfiguratorFormFields.Industry:
        values[fieldName] = configurator?.key ? mapClassicConfigToDataset(configurator.key) : Industry.Sheds;
        break;
      default:
        break;
    }
    if (
      type === ConfiguratorFormFieldType.RadioSelect &&
      radioGroup &&
      radioOption &&
      (!i || (fields[i - 1] as any).type !== ConfiguratorFormFieldType.RadioSelect)
    ) {
      values[radioGroup] = radioOption;
    }
  });

  return values;
};

export const createConfigurator = async (
  values: ConfiguratorFormValues,
  dispatch: ThunkDispatch<AppState, unknown, AnyAction>,
  newClient?: boolean,
): Promise<{ groupId: string }> => {
  const {
    [ConfiguratorFormFields.Name]: clientName = '',
    [ConfiguratorFormFields.GroupId]: groupId = undefined,
    [ConfiguratorFormFields.Industry]: industry = Industry.Sheds,
    [ConfiguratorFormFields.Key]: key,
    [ConfiguratorFormFields.Subdomain]: _subdomain,
    [ConfiguratorFormFields.Domain]: _domain,
    [ConfiguratorFormFields.Type]: type,
    [ConfiguratorFormFields.VendorDataTemplate]: vendorDataTemplateVendor = '',
    [ConfiguratorFormFields.SupplierDataTemplate]: supplierDataTemplateVendor,
    [ConfiguratorFormFields.Supplier]: supplierKey,
  } = values;
  const clientIdConfig = mapDatasetConfigToClassic(industry);
  const clientId = `${clientIdConfig}-${key}`;

  const {
    client: { groupId: newOrExistingGroupId },
  } = await dispatch(
    clientApi.endpoints.addClientInternal.initiate({
      groupName: clientName,
      groupId,
      industry,
      clientId,
      supplierClientId: type === Product.Dealer ? supplierKey : clientId,
      vendorTemplateId: vendorDataTemplateVendor,
      supplierTemplateId: supplierDataTemplateVendor,
      newClient: !!newClient,
    }),
  ).unwrap();

  dispatch(closeDialog());
  return { groupId: newOrExistingGroupId };
};

export const updateConfigurator = async (
  groupId: string,
  values: ConfiguratorFormValues,
  dispatch: ThunkDispatch<AppState, unknown, AnyAction>,
  setMessage: (message: string | undefined, tokens?: any) => void,
): Promise<void> => {
  const {
    [ConfiguratorFormFields.Name]: name = '',
    [ConfiguratorFormFields.Industry]: industry = Industry.Sheds,
    [ConfiguratorFormFields.Key]: key,
  } = values;
  const clientIdConfig = mapDatasetConfigToClassic(industry);
  const clientId = `${clientIdConfig}-${key}`;

  setMessage(I18nKeys.ConfiguratorDialogFetchingVendorData);
  const siteDetailFetch = dispatch(
    clientDataApi.endpoints.getClientDataTableData.initiate({
      dataType: ClientDataType.Vendor,
      branch: ClientDataBranch.Main,
      clientId,
      groupId,
      table: SITE_DETAIL_TABLE,
    }),
  );
  siteDetailFetch.unsubscribe();
  const { data = [] } = await siteDetailFetch;
  const [siteDetail] = data;

  setMessage(I18nKeys.ConfiguratorDialogUpdatingVendorData);
  await dispatch(
    clientDataApi.endpoints.updateClientData.initiate({
      dataType: ClientDataType.Vendor,
      clientId,
      groupId,
      data: {
        [SITE_DETAIL_TABLE]: [{ ...siteDetail, name }],
      },
      metadata: null,
      message: `Updated configurator name to ${name}`,
      // FIXME: Using unpublish will force publish any unpublished branch open for this vendor in Site Data
      branch: ClientDataBranch.Unpublished,
      newBranch: true,
      user: undefined,
    }),
  );

  await dispatch(
    clientDataApi.endpoints.publishClientData.initiate({
      dataType: ClientDataType.Vendor,
      clientId,
      groupId,
      branch: ClientDataBranch.Unpublished,
      message: `Publishing configurator name update to ${name}`,
    }),
  );

  // FIXME: publish should invalidate clientData cache
  // Invalidates the table data for the `main` branch since we're editing on unpublished
  dispatch(
    clientDataApi.util.invalidateTags([
      getClientDataCacheTag(ClientDataCacheTagType.TableData, {
        clientDataType: ClientDataType.Vendor,
        clientId,
        groupId,
        branch: Branch.Main,
      }),
    ]),
  );

  dispatch(closeDialog());
};

export const addGroup = async (
  values: ConfiguratorFormValues,
  dispatch: ThunkDispatch<AppState, unknown, AnyAction>,
): Promise<string> => {
  const { [ConfiguratorFormFields.Name]: name } = values;

  if (!name) return '';

  const { groupId = '' } = (await (
    dispatch(
      groupApi.endpoints.createGroup.initiate({
        groupId: getGroupIdFromGroupName(name),
        groupName: name,
      }) as any,
    ) as QueryActionCreatorResult<any>
  ).unwrap()) as Group;

  return groupId;
};

export const updateGroup = async (
  group: Group,
  values: ConfiguratorFormValues,
  dispatch: ThunkDispatch<AppState, unknown, AnyAction>,
): Promise<void> => {
  const { groupId } = group;
  const { [ConfiguratorFormFields.Name]: name } = values;

  if (!name) return;

  await dispatch(
    groupApi.endpoints.updateGroup.initiate({
      groupId,
      groupName: name,
    }),
  );

  dispatch(closeDialog());

  navigate(`${AppRoutes.Groups}/${groupId}`);
};

export const addConfiguratorToGroup = async (
  group: Group,
  values: ConfiguratorFormValues,
  dispatch: ThunkDispatch<AppState, unknown, AnyAction>,
): Promise<void> => {
  const { groupId, members } = group;
  const {
    [ConfiguratorFormFields.Industry]: industry = Industry.Sheds,
    [ConfiguratorFormFields.Key]: newVendor,
    [ConfiguratorFormFields.ExistingKey]: existingVendor,
  } = values;
  const key = mapDatasetConfigToClassic(industry);
  const vendor = newVendor || existingVendor;
  const clientId = `${key}-${vendor}`;
  const groupConfig = {
    key,
    vendor,
  } as ConfiguratorType;

  if (!vendor) return;

  await dispatch(
    groupApi.endpoints.addConfiguratorToGroup.initiate({
      groupId,
      configurator: groupConfig,
    }),
  );

  const salesReps = members ? members.map((member) => ({ email: member.email })) : [];

  await dispatch(
    salesRepApi.endpoints.addSalesReps.initiate({
      groupId,
      clientId,
      salesReps,
    }),
  );
};

export const getConfiguratorFormProps = (form?: string): ConfiguratorFormProps => {
  switch (form) {
    case ConfiguratorFormType.AddGroup:
      return {
        title: I18nKeys.GroupDialogAddGroupTitle,
        submitButtonLabel: I18nKeys.AddButton,
        fields: configuratorFormFields.filter((field) =>
          [ConfiguratorFormFields.Name].includes((field as any)?.fieldName),
        ),
      };
    case ConfiguratorFormType.AddGroupWithDefault:
      return {
        title: I18nKeys.GroupDialogAddGroupTitle,
        submitButtonLabel: I18nKeys.AddButton,
        fields: configuratorFormFields.filter(
          (field) => ![ConfiguratorFormFields.ExistingKey].includes((field as any)?.fieldName),
        ),
      };
    case ConfiguratorFormType.AddNewConfigurator:
      return {
        title: I18nKeys.ConfiguratorDialogAddConfiguratorTitle,
        submitButtonLabel: I18nKeys.AddButton,
        fields: configuratorFormFields.filter(
          (field) => ![ConfiguratorFormFields.ExistingKey].includes((field as any)?.fieldName),
        ),
      };
    case ConfiguratorFormType.AddExistingConfigurator:
      return {
        title: I18nKeys.ConfiguratorDialogAddConfiguratorTitle,
        submitButtonLabel: I18nKeys.AddButton,
        fields: configuratorFormFields.filter((field) =>
          [ConfiguratorFormFields.Industry, ConfiguratorFormFields.ExistingKey].includes((field as any)?.fieldName),
        ),
      };
    case ConfiguratorFormType.EditConfigurator:
      return {
        title: I18nKeys.ConfiguratorDialogUpdateConfiguratorTitle,
        submitButtonLabel: I18nKeys.DialogSaveButton,
        fields: configuratorFormFields.filter((field) =>
          [ConfiguratorFormFields.Name, ConfiguratorFormFields.Key].includes((field as any)?.fieldName),
        ),
      };
    case ConfiguratorFormType.EditGroup:
      return {
        title: I18nKeys.GroupDialogUpdateGroupTitle,
        submitButtonLabel: I18nKeys.DialogSaveButton,
        fields: configuratorFormFields.filter((field) =>
          [ConfiguratorFormFields.Name].includes((field as any)?.fieldName),
        ),
      };
    default:
      return {
        title: I18nKeys.GroupDialogAddGroupTitle,
        submitButtonLabel: I18nKeys.AddButton,
        fields: configuratorFormFields.filter((field) =>
          [ConfiguratorFormFields.Name].includes((field as any)?.fieldName),
        ),
      };
  }
};

export const s3FilesExist = (
  clientId: string,
  datatype: ClientDataType,
  publishedVersions: {
    [ClientDataType.Vendor]?: ClientPublishedVersions;
    [ClientDataType.Supplier]?: ClientPublishedVersions;
  },
): boolean => {
  const vendorKey = getVendorFromClientId(clientId);
  if (datatype === ClientDataType.Supplier) {
    return (
      s3FilesExist(clientId, ClientDataType.Vendor, publishedVersions) &&
      !!publishedVersions[datatype]?.vendors?.find(({ key, fileExists }) => vendorKey === key && fileExists)
    );
  }
  if (datatype === ClientDataType.Vendor) {
    return !!publishedVersions[datatype]?.suppliers?.find(({ key, fileExists }) => vendorKey === key && fileExists);
  }
  return false;
};

type SelectOptionProps = {
  industry: Industry;
  fields: ConfiguratorFormFieldsType[];
  suppliers: { key: string; name: string }[];
  vendors: { key: string; name: string }[];
  templateVendors: string[];
  isLoadingSuppliers: boolean;
  isLoadingVendors: boolean;
  isLoadingTemplates: boolean;
  t: TFunction;
};

export const getConfiguratorFormSelectOptions = (
  selectOptionProps: SelectOptionProps,
): ConfiguratorFormFieldsType[] => {
  const {
    industry,
    fields,
    suppliers,
    vendors,
    templateVendors,
    isLoadingSuppliers,
    isLoadingVendors,
    isLoadingTemplates,
    t,
  } = selectOptionProps;

  const [supplierOptions, vendorOptions] = [suppliers, vendors].map((options = []) =>
    Object.entries(
      options.reduce((vendorByUniqueNames, option) => {
        if (!option?.name) return vendorByUniqueNames;
        return {
          ...vendorByUniqueNames,
          [option.name]: [
            ...(vendorByUniqueNames[option.name] || []),
            `${mapDatasetConfigToClassic(industry)}-${option.key}`,
          ],
        };
      }, {} as { [key: string]: string[] }),
    ).flatMap(
      ([name, keys]) => keys.map((key) => ({ value: key, label: name, displayKey: keys.length > 1 })),
      [] as { value: string; label: string; displayKey: boolean }[],
    ),
  );

  return fields.map((field) => {
    const { fieldName = '' } = field as any;
    switch (fieldName) {
      case ConfiguratorFormFields.Supplier:
        return {
          ...field,
          selectOptions: supplierOptions,
          loading: isLoadingSuppliers,
        };
      case ConfiguratorFormFields.ExistingKey:
        return {
          ...field,
          selectOptions: vendorOptions,
          loading: isLoadingVendors,
        };
      case ConfiguratorFormFields.SupplierDataTemplate:
        return {
          ...field,
          selectOptions: [
            ...templateVendors.map((vendor) => supplierOptions.find(({ value }) => value === vendor)),
            {
              value: SUPPLIER_DATA_TEMPLATE_NONE_KEY,
              label: t(I18nKeys.ConfiguratorFormFieldSupplierDataTemplateNone),
            },
            ...supplierOptions.filter(({ value }) => !templateVendors.find((vendor) => vendor === value)),
          ].filter(Boolean) as { value: string; label: string; displayKey?: boolean }[],
          loading: isLoadingSuppliers || isLoadingTemplates,
        };
      case ConfiguratorFormFields.VendorDataTemplate:
        return {
          ...field,
          selectOptions: templateVendors
            .map((vendor) => vendorOptions.find(({ value }) => value === vendor))
            .filter(Boolean) as {
            value: string;
            label: string;
            displayKey?: boolean;
          }[],
          loading: isLoadingVendors || isLoadingTemplates,
        };
      default:
        return field;
    }
  });
};
