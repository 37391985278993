/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Stepper, Theme, Grid2 as Grid, FormControl, Box, Button } from '@mui/material';
import { reduxForm, Form, InjectedFormProps, formValueSelector, Field, submit } from 'redux-form';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../hooks';
import { ClientSignUpFormFields } from '../constants/FormFields';
import { Forms } from '../constants/Forms';
import { useGetDealerSuppliersQuery, useSharedAddClientMutation } from '../services/clientApi';
import { ClientSignUpStep } from './ClientSignUpStep';
import { SupplierAutocompleteField } from './redux-form/SupplierAutocompleteField';
import { I18nKeys } from '../constants/I18nKeys';
import { InputField } from './redux-form/InputField';
import { passwordValidation, required } from '../utils/reduxFormUtils';
import { LoadingButton } from './LoadingButton';
import { ClientSupplierPreview } from './ClientSupplierPreview';

export interface FormData {
  [ClientSignUpFormFields.Supplier]: { id: string; label: string };
  [ClientSignUpFormFields.Industry]: string;
  [ClientSignUpFormFields.Email]: string;
  [ClientSignUpFormFields.Password]: string;
}

const useStyles = makeStyles((_: Theme) => ({
  formContainer: {
    padding: '16px 0px 0px',
  },
  stepConnector: {
    '& .MuiStepConnector-lineVertical': {
      border: 'none',
    },
  },
}));

interface CustomProps {
  onSubmit: { (values: FormData): void };
}

type FormProps = CustomProps & InjectedFormProps<FormData, CustomProps>;

const ClientSignUpFormComponent: React.FC<FormProps> = ({ handleSubmit, onSubmit }: FormProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const classes = useStyles();

  const industry = useAppSelector((state) =>
    formValueSelector(Forms.ClientSignUp)(state, ClientSignUpFormFields.Industry),
  );
  const supplier = useAppSelector((state) =>
    formValueSelector(Forms.ClientSignUp)(state, ClientSignUpFormFields.Supplier),
  );

  const [step, setStep] = React.useState<number>(0);

  const { data: suppliers, isLoading: isLoadingSuppliers } = useGetDealerSuppliersQuery(industry, {
    skip: !industry,
  });

  const [_, { isLoading: isAddingClient }] = useSharedAddClientMutation();

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Stepper activeStep={step} orientation="vertical" connector={null}>
        <ClientSignUpStep
          index={0}
          expanded={step === 0}
          header={t(I18nKeys.ClientSignUpStep1Header, { industry })}
          message={t(I18nKeys.ClientSignUpStep1Message, { industry })}
        >
          <Grid container spacing="16px" sx={{ height: '50vh' }}>
            <Grid key={ClientSignUpFormFields.Supplier} size={6}>
              <FormControl fullWidth>
                <Field
                  name={ClientSignUpFormFields.Supplier}
                  label="Search"
                  variant="outlined"
                  component={SupplierAutocompleteField}
                  options={suppliers}
                  disabled={isLoadingSuppliers}
                />
              </FormControl>
            </Grid>
            <ClientSupplierPreview supplier={supplier} />
            <Box mt={1}>
              <Button
                variant="contained"
                color="primary"
                disabled={!supplier}
                onClick={() => {
                  setStep(1);
                }}
              >
                Continue
              </Button>
            </Box>
          </Grid>
        </ClientSignUpStep>
        {step === 1 && (
          <ClientSignUpStep
            index={1}
            expanded={step === 1}
            header={t(I18nKeys.ClientSignUpStep2Header, { industry })}
            message={t(I18nKeys.ClientSignUpStep2Message, { industry })}
          >
            <Grid container spacing="16px" className={classes?.formContainer}>
              <Grid container size={{ xs: 12, sm: 12 }}>
                <Grid key={ClientSignUpFormFields.Email} size={{ xs: 12, sm: 6 }}>
                  <FormControl fullWidth>
                    <Field
                      name={ClientSignUpFormFields.Email}
                      label="Email"
                      variant="filled"
                      component={InputField}
                      disabled
                      validate={required}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid key={ClientSignUpFormFields.Password} size={{ xs: 12, sm: 6 }}>
                <FormControl fullWidth>
                  <Field
                    name={ClientSignUpFormFields.Password}
                    label="Password"
                    variant="filled"
                    component={InputField}
                    validate={passwordValidation}
                  />
                </FormControl>
              </Grid>
              <Grid key={ClientSignUpFormFields.ConfirmPassword} size={{ xs: 12, sm: 6 }}>
                <FormControl fullWidth>
                  <Field
                    name={ClientSignUpFormFields.ConfirmPassword}
                    label="Confirm Password"
                    variant="filled"
                    component={InputField}
                    validate={passwordValidation}
                  />
                </FormControl>
              </Grid>
              <Box mt={1}>
                <LoadingButton
                  loading={isAddingClient}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    dispatch(submit(Forms.ClientSignUp));
                  }}
                >
                  Create Site
                </LoadingButton>
              </Box>
            </Grid>
          </ClientSignUpStep>
        )}
      </Stepper>
    </Form>
  );
};

export const ClientSignUpForm = reduxForm<FormData, CustomProps>({
  form: Forms.ClientSignUp,
})(ClientSignUpFormComponent);
