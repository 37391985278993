import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Card, CardMedia, Grid2 as Grid, Slide, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FlipCard } from './FlipCard';
import { I18nKeys } from '../constants/I18nKeys';

const useStyles = makeStyles(() => ({
  initialStylesPreview: {
    backgroundColor: '#F2F2F2',
    borderRadius: '8px',
    alignContent: 'center',
    textAlign: 'center',
    height: '100%',
    width: 'calc(50vh + 20px)',
    fontSize: '14px',
    color: '#323B4BB2',
  },
  stylesPreview: {
    position: 'relative',
    width: 'calc(50vh + 20px)',
    height: 'calc(72%)',
    paddingRight: '20px',
    paddingBottom: '20px',
    overflow: 'hidden',
  },
  logoPreview: {
    width: '50vh',
    height: 'calc(28%)',
  },
  stylesText: {
    width: '100%',
    textAlign: 'right',
    position: 'absolute',
    bottom: '10px',
    right: '10px',
    fontSize: '14px',
    color: '#323B4BB2',
  },
  flipCard: {
    maxHeight: 'calc(50vh * 0.36 - 10px)',
    backgroundColor: '#F2F2F2',
  },
  logoCard: {
    border: '1px solid rgba(0, 0, 0, 0.1)',
    height: '100%',
    alignContent: 'center',
    justifyItems: 'center',
  },
}));

type Props = { supplier?: { id?: string; label?: string; logoUrl?: string; logoBarColor?: string; styles?: string[] } };

const getInitialImagesState = (styles: string[]) => Object.fromEntries([...styles.slice(0, 4).map((s) => [s, true])]);

export const ClientSupplierPreview: React.FC<Props> = ({
  supplier: { id, styles = [], logoBarColor, logoUrl = '' } = { id: undefined },
}: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [slideIn, setSlideIn] = useState<boolean>(false);
  const [supplierKey, setSupplierKey] = useState<string | undefined>(id);
  const [loading, setLoading] = useState<{
    [key: string]: boolean;
  }>(getInitialImagesState(styles));
  const iconSectionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (id === supplierKey) return;
    setSlideIn(false);
    setSupplierKey(id);
    setLoading(getInitialImagesState(styles));
  }, [id, supplierKey]);

  if (!id)
    return (
      <Grid size={6}>
        <Typography variant="subtitle1" className={classes.initialStylesPreview}>
          {t(I18nKeys.ClientSignUpStylePreview)}
        </Typography>
      </Grid>
    );
  return (
    <Grid
      // Key will ensure animation starts over when switching between suppliers
      key={id}
      size={6}
      direction="column"
      sx={{
        height: 'calc(100% - 5px)',
      }}
    >
      <Grid ref={iconSectionRef} container spacing="20px" size={12} className={classes.stylesPreview}>
        {styles.slice(0, 4).map((img, i, arr) => (
          <Grid size={6}>
            <FlipCard
              delay={i * 0.5}
              className={classes.flipCard}
              loading={loading[img]}
              onAnimationComplete={() => {
                if (i === arr.length - 1) {
                  setSlideIn(true);
                }
              }}
            >
              <CardMedia
                component="img"
                image={img}
                onLoad={() =>
                  setLoading((prevLoading) => ({
                    ...prevLoading,
                    [img]: false,
                  }))
                }
                alt="Style Preview"
                sx={{
                  maxHeight: '90%',
                  maxWidth: '90%',
                  objectFit: 'contain',
                }}
              />
            </FlipCard>
          </Grid>
        ))}
        <Slide
          in={!!styles.slice(4).length && slideIn && supplierKey === id}
          direction="right"
          container={iconSectionRef.current}
          timeout={750}
        >
          <Typography variant="subtitle1" className={classes.stylesText}>
            {`+ ${styles.slice(4).length} more styles`}
          </Typography>
        </Slide>
      </Grid>
      <Grid size={12} className={classes.logoPreview}>
        <Card
          elevation={0}
          className={classes.logoCard}
          sx={{
            backgroundColor: logoBarColor,
          }}
        >
          <CardMedia
            component="img"
            image={logoUrl}
            alt="Logo Preview"
            sx={{
              maxHeight: '75%',
              maxWidth: '75%',
              objectFit: 'contain',
            }}
          />
        </Card>
      </Grid>
    </Grid>
  );
};
