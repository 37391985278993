import { TFunction } from 'i18next';
import { createElement } from 'react';
import {
  GridColDef,
  GRID_TREE_DATA_GROUPING_FIELD,
  GridRenderCellParams,
  GridCellParams,
} from '@mui/x-data-grid-premium';
import { CircularProgress } from '@mui/material';
import { Permission } from '@idearoom/types';
import { Dispatch } from 'redux';
import { MUIDataGrid, MUIDataGridColumn, muiDataGridColumnWidths } from '../constants/MUIDataGrid';
import { getDateAndMonthString, getDateWithMonthAbbreviation } from './dateUtils';
import { RowGroupExpandButton } from '../components/RowGroupExpandButton';
import { sortByAlphanumericParts } from './sortUtils';
import { SortDirection } from '../constants/SortDirection';
import { formatPhoneNumber } from './phoneNumberUtils';
import { NewWindowLink } from '../components/NewWindowLink';
import { getPermissionLabel } from '../constants/Permissions';
import { Dealer } from '../types/Dealer';
import { ColumnDefinitionParams } from '../types/MUIDataGrid';
import { ActionsMenu } from '../components/ActionsMenu';
import { I18nKeys } from '../constants/I18nKeys';
import { openConfirmationDialog } from '../ducks/confirmation';
import { Dialogs } from '../constants/Dialogs';
import { openDialog } from '../ducks/dialogSlice';
import { removeMember, resendMemberInvitation, resetMemberPassword, sendNewMemberInvitation } from '../ducks/group';
import { UserStatus } from '../constants/User';
import { initiateImpersonation } from '../ducks/currentUserSlice';
import { removeDealer } from '../ducks/dealersSlice';

export const getValueOrEmpty = (value?: string) => (value ? `${value} ` : '');

export const dateFormatter = (date?: string) => (date ? getDateWithMonthAbbreviation(date, true) : '');
export const dateMonthFormatter = (date?: string) => (date ? getDateAndMonthString(date) : '');

/**
 * Used to get the initial value for a column
 *
 * @param params column definition params for the table
 * @param column column name
 * @returns value for the column
 */
const getValueGetter =
  (params: ColumnDefinitionParams, column: MUIDataGridColumn | typeof GRID_TREE_DATA_GROUPING_FIELD) =>
  (_: any, row: any): string => {
    const { table } = params;

    const isRowGroupRow = table === MUIDataGrid.AllUsers && row?.groupName;
    const { name, email, emailAddress, phone, phoneNumber, groupName } = row;

    switch (column) {
      case MUIDataGridColumn.Email:
        return !isRowGroupRow ? email || emailAddress : '';
      case MUIDataGridColumn.Phone:
        return !isRowGroupRow ? phone || phoneNumber : '';
      case MUIDataGridColumn.Name:
        return !isRowGroupRow ? name || email || emailAddress : groupName;
      case MUIDataGridColumn.Site:
        return row?.dealerURL || '';
      case GRID_TREE_DATA_GROUPING_FIELD:
        return '';
      default:
        return row[column];
    }
  };

/**
 * Formats the value for a column after the value getter has been called
 *
 * @param params column definition params for the table
 * @param column column name
 * @returns formatted value for the column
 */
const getValueFormatter =
  (params: ColumnDefinitionParams, column: MUIDataGridColumn | typeof GRID_TREE_DATA_GROUPING_FIELD, t: TFunction) =>
  (value: any): string => {
    const { table } = params;

    if (column === MUIDataGridColumn.Phone) {
      return formatPhoneNumber(value);
    }

    if (column === MUIDataGridColumn.Permissions) {
      return (value || []).map((p: Permission) => getPermissionLabel(p, t)).join(', ');
    }

    if (column === MUIDataGridColumn.Dealers && (table === MUIDataGrid.AllUsers || table === MUIDataGrid.Users)) {
      const { dealers = [] } = params;
      return value
        .map((dealerKey: string) => dealers.find((d: Dealer) => d.key === dealerKey)?.name || dealerKey)
        .join(', ');
    }

    return value;
  };

/**
 * Gets a list of actions that should be displayed in the Actions menu for a row
 *
 * @param params column definition params for the table
 * @param row row data
 * @param dispatch redux dispatch function
 * @param t i18n translation function
 * @returns actions menu props
 */
export const getActionsMenuProps = (
  params: ColumnDefinitionParams,
  row: any,
  dispatch: Dispatch<any>,
  t: TFunction,
): {
  id: string;
  actions: {
    label: string;
    onClick: (handleClose: () => void) => void;
    ideaRoomOnly?: boolean;
  }[];
} => {
  const { table } = params;

  switch (table) {
    case MUIDataGrid.AllUsers:
    case MUIDataGrid.Users:
      return {
        id: 'user-group-menu',
        actions: [
          ...(row.status === UserStatus.Confirmed
            ? [
                {
                  label: t(I18nKeys.GroupMemberMenuResetPassword),
                  onClick: (handleClose: () => void) => {
                    dispatch(resetMemberPassword(row));
                    handleClose();
                  },
                },
              ]
            : []),
          ...(row.status === UserStatus.ResetRequired
            ? [
                {
                  label: t(I18nKeys.GroupMemberMenuSendInvitation),
                  onClick: (handleClose: () => void) => {
                    dispatch(sendNewMemberInvitation(row));
                    handleClose();
                  },
                },
              ]
            : []),
          ...(row.status === UserStatus.ForceChangePassword
            ? [
                {
                  label: t(I18nKeys.GroupMemberMenuResendInvitation),
                  onClick: (handleClose: () => void) => {
                    dispatch(resendMemberInvitation(row));
                    handleClose();
                  },
                },
              ]
            : []),
          {
            label: t(I18nKeys.GroupMemberMenuImpersonate),
            onClick: (handleClose) => {
              dispatch(initiateImpersonation(row));
              handleClose();
            },
            ideaRoomOnly: true,
          },
          {
            label: t(I18nKeys.GroupMemberMenuDelete),
            onClick: (handleClose) => {
              dispatch(
                openConfirmationDialog(
                  t(I18nKeys.GroupMemberMenuConfirmDeleteTitle),
                  t(I18nKeys.GroupMemberMenuConfirmDeleteUserMessage, { userEmail: row?.email }),
                  undefined,
                  [
                    {
                      actions: [removeMember(row)],
                    },
                  ],
                ),
              );
              dispatch(openDialog({ dialog: Dialogs.Confirmation }));
              handleClose();
            },
          },
        ],
      };
    case MUIDataGrid.Dealers:
      return {
        id: 'dealer-menu',
        actions: [
          {
            label: t(I18nKeys.DealerMenuRemoveOption),
            onClick: (handleClose) => {
              dispatch(
                openConfirmationDialog(
                  t(I18nKeys.DealerMenuConfirmRemoveTitle),
                  t(I18nKeys.DealerMenuConfirmRemoveMessage),
                  undefined,
                  [
                    {
                      actions: [removeDealer({ clientId: params.clientId, dealer: row })],
                    },
                  ],
                ),
              );
              dispatch(openDialog({ dialog: Dialogs.Confirmation }));
              handleClose();
            },
          },
        ],
      };
    default:
      return {
        id: 'menu',
        actions: [],
      };
  }
};

/**
 * Returns column specific cell renderers for the column (if one exists)
 *
 * @param params column definition params for the table
 * @param column column name
 * @param dispatch redux dispatch function
 * @param t i18n translation function
 * @returns column cell renderer
 */
export const getColumnCellRenderer = (
  params: ColumnDefinitionParams,
  column: MUIDataGridColumn | typeof GRID_TREE_DATA_GROUPING_FIELD,
  dispatch: Dispatch<any>,
  t: TFunction,
): { renderCell: GridColDef['renderCell'] } | undefined => {
  const { table } = params;
  if (column === GRID_TREE_DATA_GROUPING_FIELD) {
    return {
      renderCell: RowGroupExpandButton,
    };
  }

  if (column === MUIDataGridColumn.Site) {
    return {
      renderCell: (p: GridRenderCellParams) => {
        const { row: { dealerURL: site = '' } = {} } = p;
        return createElement(NewWindowLink, { link: site });
      },
    };
  }

  if (column === MUIDataGridColumn.Actions) {
    return {
      renderCell: (p: GridRenderCellParams) => {
        const { row = {} } = p;
        const { updating = false } = row;

        if (updating) return createElement(CircularProgress, { size: 24, color: 'primary' });
        if ((table === MUIDataGrid.Users || table === MUIDataGrid.AllUsers) && !params.showGroupMemberMenu) return null;
        return createElement(ActionsMenu, getActionsMenuProps(params, row, dispatch, t));
      },
    };
  }
  return undefined;
};

/**
 * Gets a classname getter for a column
 *
 * @param params column definition params for the table
 * @param classes css classes
 * @param column column name
 * @returns classname getter
 */
const getCellClassName = (
  params: ColumnDefinitionParams,
  classes: Record<string, string>,
  column: MUIDataGridColumn | typeof GRID_TREE_DATA_GROUPING_FIELD,
) => {
  const { table } = params;
  if (table === MUIDataGrid.AllUsers && column === MUIDataGridColumn.Name) {
    return {
      cellClassName: (p: GridCellParams<any, any, number>) => {
        const { row: { groupId = '' } = {} } = p;
        if (groupId && table === MUIDataGrid.AllUsers) return `${classes.groupNameCell}`;
        return '';
      },
    };
  }
  return {};
};

/**
 * Gets the column definition for a column in a table
 *
 * @param params column definition params for the table
 * @param column column name
 * @param dispatch redux dispatch function
 * @param classes css classes
 * @param t i18n translation function
 * @returns column definition
 */
export const getDefaultColumnDefinition = (
  params: ColumnDefinitionParams,
  column: MUIDataGridColumn | typeof GRID_TREE_DATA_GROUPING_FIELD,
  classes: Record<string, string>,
  dispatch: Dispatch<any>,
  t: TFunction,
): GridColDef => {
  const { table, editable } = params;

  return {
    // Columns must have different field names between tables to avoid ordering conflicts
    field: `${table}-${column}`,
    editable,
    valueFormatter: getValueFormatter(params, column, t),
    valueGetter: getValueGetter(params, column),
    sortComparator: (a: any, b: any): number => sortByAlphanumericParts(a, b, SortDirection.Asc),
    ...getCellClassName(params, classes, column),
    ...getColumnCellRenderer(params, column, dispatch, t),
    ...muiDataGridColumnWidths[column],
    ...(column !== GRID_TREE_DATA_GROUPING_FIELD && [MUIDataGridColumn.Actions, MUIDataGridColumn.Site].includes(column)
      ? { align: 'center' }
      : {}),
    ...([MUIDataGridColumn.Actions, GRID_TREE_DATA_GROUPING_FIELD].includes(column)
      ? { headerName: '', pinned: column === MUIDataGridColumn.Actions ? 'right' : 'left' }
      : { headerName: t(`table-header-${column}`) }),
    ...(column === MUIDataGridColumn.Actions ? { type: 'actions' } : { type: 'string' }),
  };
};
